/* @import url("https://fonts.googleapis.com/css?family=Arvo:700|Open+Sans"); */
/* html {
  overflow-x: hidden;
} */
.landing {
  align-items: center;
  /* padding-left: 350px; */
  background: #fff;
  font-family: "Open Sans", sans-serif;
  /* -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; */
  -webkit-font-smoothing: subpixel-antialiased;
  color: #000000;
  /* line-height: 25px; */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
}

/* h1,
h2,
h3,
h4 {
  font-size: 38px;
  color: #000000;
  font-weight: 800;
  font-family: "Arvo", serif;
} */

a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

a:hover {
  text-decoration: none;
}

a a:focus {
  outline: none;
}

/* p {
  font-weight: 320; 
  font-family: "Open Sans", sans-serif;
  margin: 0px;
  font-size: 19.125px; /* content */
/* }   */

/* ul,
ol {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

ul li,
ol li {
  list-style: none;
} */

a:not([href]):not([tabindex]) {
  color: #fff;
}

.section-header {
  margin-bottom: 38.25px;
  line-height: 42.5px;
}

.section-header p {
 text-align: center; 
  font-size: 38.25px;
  font-weight: 700;
  line-height: 42.5px;
}

.section-title {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 4rem;
  margin-bottom: 1.6rem;
}
.section-subtitle {
  font-size: 38.25px;
  font-weight: 700;
  line-height: 42.5px;
  cursor: pointer;
}
.para {
  font-size: 22px;
  font-weight: 400px;
  line-height: 30px;
}

.shape {
  height: 1px;
  margin: 0 auto 30px;
  position: relative;
  width: 60px;
  background-color: #f1c232;
}

.padding-none {
  padding: 0px;
}

.bg-gray {
  background: #f9f9f9;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(61, 96, 244, 0.3);
}

.btn {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 30px;
  cursor: pointer;
  font-weight: 400;
  color: #fff;
  border-radius: 30px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: inline-block;
  background-color: #f1c232;
}

.btn:focus,
.btn:active {
  box-shadow: none;
  outline: none;
  color: #fff;
}
.getbtn {
  font-size: 18px;
  font-weight: 500;
  background-color: #f1c232;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  width: 160px;
  height: 50px;
  border: none;
}
.getbtn:hover {
  color: #f1c232;
  background: transparent;
  border: 1px dotted #f1c232;
  box-shadow: 0 6px 22px rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.loginbtn {
  background-color: #f1c232;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  width: 80px;
  height: 40px;
  border: none;
  font-size: 18px;
}
.loginbtn:hover {
  color: #f1c232;
  background: transparent;
  border: 1px dotted #f1c232;
  box-shadow: 0 6px 22px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.btn-common {
  background-color: #f1c232;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  border-radius: 30px;
  width: auto;
  border: none;
}

.btn-common:hover {
  color: #f1c232;
  background: transparent;
  border: 1px dotted #f1c232;
  box-shadow: 0 6px 22px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

.btn-border {
  color: #f1c232;
  background-color: transparent;
  border: 1px solid #f1c232;
  border-radius: 30px;
  text-transform: uppercase;
}

.btn-border:hover {
  color: #fff;
  border-color: #f1c232;
  background-color: #f1c232;
}

.btn-border:focus {
  color: #fff;
  border-color: #f1c232;
  background-color: #f1c232;
}

.btn-lg {
  padding: 14px 33px;
  text-transform: uppercase;
  font-size: 16px;
}

.btn-rm {
  padding: 7px 0px;
  color: #f1c232;
  text-transform: capitalize;
}

.btn-rm:hover {
  color: #f1c232;
}

.btn:focus {
  outline: none !important;
}

.icon-close,
.icon-check {
  color: #f1c232;
}

.social-icon a {
  text-decoration-line: none;
  color: #666;
  background: #fff;
  width: 32px;
  height: 32px;
  line-height: 34px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  font-size: 17px;
  margin: 15px 6px 12px 4px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.social-icon a:hover {
  color: #fff !important;
  text-decoration-line: none;
}

.social-icon .envelope:hover {
  background: #0378D4;
}

.social-icon .twitter:hover {
  background: #4a9cec;
}

.social-icon .instagram:hover {
  background: #d6274d;
}

.social-icon .linkedin:hover {
  background: #1260a2;
}

.social-icon .google:hover {
  background: #ce332a;
}

/* ScrollToTop */
a.back-to-top {
  display: none;
  position: fixed;
  bottom: 18px;
  right: 15px;
  text-decoration: none;
}

a.back-to-top i {
  display: block;
  font-size: 22px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background: #f1c232;
  border-radius: 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}

a.back-to-top:hover,
a.back-to-top:focus {
  text-decoration: none;
}

/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999999;
}

.loader {
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

#loader-1:before,
#loader-1:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 7px solid transparent;
  border-top-color: #f1c232;
}

#loader-1:before {
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after {
  border: 7px solid #f2f2f2;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.section-padding {
  padding: 60px 0;
  min-height: auto;
}

hr {
  border-top: 1px dotted #ddd;
}

/* ==========================================================================
  8. Features Section Style
   ========================================================================== */
#features {
  background: #fff;
  font-size: 32px;
  /* margin-left: 100px; */
}

#features .icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  z-index: 1;
}

#features .content-left span {
  float: right;
  font-size: 18px;
}

#features .content-left .text {
  text-align: right;
}

#features .content-right span {
  float: left;
}

#features .box-item {
  box-shadow: 0 0 12px #f4f4f4;
  padding: 15px;
  line-height: 22px;
  margin-top: 30px;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -mox-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: 0;
}

#features .box-item .icon {
  text-align: center;
  margin: 12px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .icon i {
  color: #f1c232;
  font-size: 32px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .text h4 {
  cursor: pointer;
  font-size: 28px;
  font-weight: 600;
  line-height: 22px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .text p {
  font-size: 18px;
  line-height: 26px;
}

#features .box-item:hover {
  box-shadow: 0 10px 22px 10px rgba(27, 38, 49, 0.1);
}

#features .box-item:hover h4 {
  color: #f1c232;
}

#features .show-box {
  margin-top: 50px;
}

#features .show-box img {
  width: 100%;
}

/* solutions Item */

.solution {
  /* width: 100%; */
  min-height: auto;
  /* margin-left: 100px; */
}
.solutions-item {
  padding: 30px;
  border-radius: 4px;
  text-align: center;
  margin: 30px 0;
  box-shadow: 0 0 12px #f4f4f4;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  /* width: 200px; */
}

.solutions-item .icon {
  border: 1px solid #f1f1f1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.solutions-item .icon i {
  font-size: 30px;
  color: #f1c232;
  line-height: 70px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.solutions-item .solutions-content h3 {
  margin-top: 10px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.solutions-item .solutions-content h3 a {
  font-size: 16px;
  color: #000000;
}

.solutions-item .solutions-content h3 a:hover {
  color: #f1c232;
}

.solutions-item:hover {
  box-shadow: 0 10px 22px 10px rgba(27, 38, 49, 0.1);
}

.solutions-item:hover .icon {
  background: #f1c232;
}

.solutions-item:hover .icon i {
  color: #fff;
}

#solution {
  position: relative;
  min-height: 600px;
}

.about-area .about-wrapper {
  height: 100%;
  width: 100%;
  display: table;
}

.about-area .about-wrapper > div {
  vertical-align: middle;
  display: table-cell;
}

.about-area img {
  border-radius: 4px;
}

.about-area .content {
  margin-top: 15px;
}

.about-area .content p {
  margin-bottom: 30px;
}

#cta {
  padding: 30px 0;
  min-height: auto;
}

.navbar-brand {
  position: relative;
  padding: 0px;
}

.top-nav-collapse {
  background: #fff;
  z-index: 999999;
  padding: 5px !important;
  top: 0px !important;
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.06);
  background: #fff !important;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.top-nav-collapse .navbar-brand {
  top: 0px;
}

.top-nav-collapse .navbar-brand img {
  width: 70%;
}

.top-nav-collapse .navbar-nav .nav-link {
  color: #000000 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.top-nav-collapse .navbar-nav .nav-link:hover {
  cursor: pointer;
  color: #f1c232 !important;
  border-color: #f1c232 !important;
}

.top-nav-collapse .navbar-nav .nav-link:hover {
  color: #f1c232 !important;
}

.navbar-expand-md .navbar-toggler {
  background: transparent;
  border: 1px solid #f1c232;
  color: #f1c232;
  border-radius: 4px;
  cursor: pointer;
}

.navbar-brand img {
  width: 70%;
}

.top-nav-collapse .navbar-nav li.active a.nav-link {
  color: #f1c232 !important;
  border-color: #f1c232;
}

.indigo {
  background: transparent;
}

.menu-bg {
  background: transparent;
}

.navbar-expand-md .navbar-nav .nav-link i {
  font-size: 16px;
  margin-left: 5px;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar-expand-md .navbar-nav .nav-item {
  padding-left: 25px;
}

.navbar-expand-md .navbar-nav .nav-link {
  color: #000000;
  font-weight: 400;
  padding: 7px 0;
  cursor: pointer;
  position: relative;
  background: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar-expand-md .navbar-nav .nav-link:before {
  background-color: #f1c232;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  margin: 0 auto;
  width: 0;
  top: 35px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: width 1s;
  -ms-transition: width 1s;
  -webkit-transition: width 1s;
}

.navbar-expand-md .navbar-nav li a:hover,
.navbar-expand-md .navbar-nav li .active > a,
.navbar-expand-md .navbar-nav li a:focus {
  color: #fff;
  outline: none;
}

.navbar-expand-md .navbar-nav .active > .nav-link,
.navbar-expand-md .navbar-nav .nav-link.active,
.navbar-expand-md .navbar-nav .nav-link.open,
.navbar-expand-md .navbar-nav .open > .nav-link {
  color: #f1c232 !important;
  width: 100%;
}

.navbar-expand-md .navbar-nav .nav-link:hover,
.navbar-expand-md .navbar-nav .nav-link:hover::before,
.navbar-expand-md .navbar-nav .nav-link:focus {
  color: #f1c232;
  width: 100%;
  transition: width 1s;
  -webkit-transition: width 1s;
  -ms-transition: width 1s;
}

.navbar {
  padding: 10px 0;
  background-color: white;
}

/* .navbar li.active a.nav-link {
  color: #preset;
} */

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  z-index: 99;
  min-width: 210px;
  background-color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  animation: fadeIn 0.4s;
  -webkit-animation: fadeIn 0.4s;
  -moz-animation: fadeIn 0.4s;
  -o-animation: fadeIn 0.4s;
  -ms-animation: fadeIn 0.4s;
}

.dropdown-menu:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 100%;
  left: 20%;
  margin-left: -5px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  text-align: left;
  top: 100%;
  border: none;
  animation: fadeIn 0.4s;
  -webkit-animation: fadeIn 0.4s;
  -moz-animation: fadeIn 0.4s;
  -o-animation: fadeIn 0.4s;
  -ms-animation: fadeIn 0.4s;
}

.dropdown .dropdown-menu .dropdown-item {
  width: 100%;
  padding: 12px 20px;
  font-size: 16px;
  color: #000000;
  border-bottom: 1px solid #f1f1f1;
  text-decoration: none;
  display: inline-block;
  float: left;
  clear: both;
  position: relative;
  outline: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.dropdown .dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown .dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown .dropdown-item:focus,
.dropdown .dropdown-item:hover,
.dropdown .dropdown-item.active {
  color: #f1c232;
  background: #f7f7f7;
}

.dropdown-item.active,
.dropdown-item:active {
  background: transparent;
}

.fadeInUpMenu {
  -webkit-animation-name: fadeInUpMenu;
  animation-name: fadeInUpMenu;
}

/* ==========================================================================
3. Hero Area
========================================================================== */
#hero-area {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 700px;
  position: relative;
  overflow: hidden;
  padding: 180px 0 50px;
}

#hero-area .contents .head-title {
  color: #222222;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 4rem;
  margin-bottom: 1.6rem;
}

#hero-area .contents .header-.btn {
  margin-top: 20px;
  color: #222222;
}

#hero-area .contents .header-.btn .btn {
  margin-right: 10px;
}

.sloder-img {
  background: #000000;
}

/* Team Item */
.team-item:hover {
  box-shadow: 0 10px 22px 10px rgba(27, 38, 49, 0.1);
}

.team-item {
  margin: 15px 0;
  border-radius: 0px;
  box-shadow: 0px 2px 18px 0px rgba(198, 198, 198, 0.3);
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  background: #fff;
}

.team-item .team-img {
  float: left;
  width: 200px;
  margin-right: 30px;
}

.team-item .contetn {
  padding: 15px 50px;
  height: 200px;
}

.team-item .social-icons {
  padding-top: 11px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.team-item .social-icons li {
  display: inline-block;
  margin-right: 10px;
}

.team-item .social-icons li a {
  letter-spacing: 0px;
  outline: 0 !important;
}

.team-item .social-icons li a i {
  font-size: 18px;
  display: block;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.team-item .social-icons li .lni-envelope {
  color: #417af3;
}

.team-item .social-icons li .lni-twitter-filled {
  color: #00aced;
}

.team-item .social-icons li .lni-instagram-filled {
  color: #fb3958;
}

.team-item .info-text {
  margin-bottom: 10px;
}

.team-item .info-text h3 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 5px;
}

.team-item .info-text h3 a {
  color: #333;
}

.team-item .info-text h3 a:hover {
  color: #f1c232;
}

.team-item .info-text p {
  margin: 0;
  color: #888;
}

.team-item:hover .team-overlay {
  opacity: 1;
}

.institute {
  position: relative;
  background-color: #F1C232;
  min-height: auto;
  width: 100%;
}

.institute-item {
  background: #fff;
  border-radius: 4px;
  text-align: center;
  padding: 30px 20px;
}

.institute-item .img-thumb {
  position: relative;
  margin: 15px 15px 15px 0px;
}

.institute-item .img-thumb img {
  border-radius: 50%;
  /* display: inline-block; */
  width: inherit;
  padding: 7px;
  min-height: auto;
}

.institute-item .content {
  overflow: hidden;
}

.institute-item .content .description {
  width: 100%;
  color: #333;
}

.institute-item .content .star-icon i {
  color: #F1C232;
}

.institute-item .info h2 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 30px;
  margin: 0;
}

.institute-item .info h2 a {
  color: #333;
}

.institute-item .info h3 {
  margin: 0;
  clear: both;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 10px;
}

.institute-item .info h3 a {
  color: #333;
}

.institute-item .info .indicator {
  font-size: 26px;
  font-weight: 700;
  color: #F1C232;
}

.institute-item .icon-social {
  margin-top: 30px;
}

.institute-item .icon-social a {
  color: #666;
  background: #fff;
  width: 32px;
  height: 32px;
  line-height: 34px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  font-size: 15px;
  margin: 15px 6px 12px 4px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.institute-item .icon-social a:hover {
  color: #fff;
}

.institute-item .icon-social .envelope:hover {
  background: #3b5999;
}

.institute-item .icon-social .twitter:hover {
  background: #4A9CEC;
}

.institute-item .icon-social .instagram:hover {
  background: #D6274D;
}

.institute-item .icon-social .linkedin:hover {
  background: #1260A2;
}

.institute-item .icon-social .google:hover {
  background: #CE332A;
}

.owl-pagination {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -40px;
}

.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-carousel .btn.owl-dot {
  display: inline-block;
  zoom: 1;
  display: inline;
  text-align: center;
}

.owl-carousel .btn.owl-dot span {
  display: block;
  width: 12px;
  height: 12px;
  margin: 2px 4px;
  filter: alpha(opacity=50);
  opacity: 1;
  border-radius: 30px;
  background: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

.owl-carousel .btn.owl-dot.active span,
.owl-carousel .btn.owl-dot.clickable,
.owl-carousel .btn.owl-dot:hover span {
  background: #f1c232;
}

.slick-slider {
  padding: 80px 0;
}

.slider-center img {
  opacity: 0.7;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  padding: 63px 63px;
  position: relative;
  text-align: center;
}

.slider-center .slick-center img {
  -moz-transform: scale(1.9);
  -ms-transform: scale(1.9);
  -o-transform: scale(1.9);
  -webkit-transform: scale(1.9);
  opacity: 1;
  transform: scale(1.9);
}

.form-control {
  width: 100%;
  margin-bottom: 20px;
  font-size: 14px;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  padding: 10px;
  border: 1px solid #f1f1f1;
}

.form-control:focus {
  border-color: #f1c232;
  box-shadow: none;
  outline: none;
}

textarea {
  border-radius: 14px !important;
}

.form-control:focus {
  box-shadow: none;
  outline: none;
}

.btn.disabled,
.btn:disabled {
  opacity: 1;
}
.contact {
  min-height: auto;
}
.contactForm {
  align-items: center;
  margin-top: 80px;
  padding-left: 90px;
}
.contact-form-area h2 {
  font-size: 18px;
  text-transform: uppercase;
}

/* .h3 {
  float: right;
  font-size: 16px;
} */

/*cta
*/

/* Footer Area Start */
.footer-area {
  position: relative;
  padding: 150px 0px 0px;
  background: #fff;
}

.footer-area .footer-titel {
  font-size: 18.0625px;
  color: #000000;
  font-weight: 600;
  padding-bottom: 20px;
  letter-spacing: 0.5px;
}

.footer-area .footer-titel span {
  color: #ccc;
  font-weight: 400;
}

.footer-area .textwidget p {
  color: #030303;
}


.footer-area .footer-link li {
  margin-bottom: 10px;
}

.footer-area .footer-link li a {
  color: #070707;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  text-decoration: none;
}

.footer-area .footer-link li a:hover {
  color: #f1c232;
}

.footer-area .address li {
  margin-bottom: 20px;
}

.footer-area .address li a {
  color: #030303;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
}

.footer-area .address li a i {
  font-size: 22px;
  width: 32px;
  vertical-align: middle;
  height: 36px;
  text-align: center;
  display: inline-block;
  float: left;
  margin-right: 5px;
  line-height: 32px;
}

.footer-area #subscribe-form {
  margin-top: 10px;
}

.footer-area #subscribe-form .form-group {
  position: relative;
}

.footer-area #subscribe-form .form-group .btn-common {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px 15px;
  height: 52px;
  border-radius: 0;
  background: transparent;
  color: #f1c232;
}

#copyright .copyright-content {
  border-top: 1px solid #d5d4e9;
  padding: 15px 0;
  margin: 45px 0 0px;
}

#copyright p {
  line-height: 42px;
  color: #333;
  text-align: center;
  margin: 0;
  font-size: 15px;
}

#copyright p a {
  color: #01a85a;
  text-decoration-line: none;
}

.social-icon a {
  color: #272727;
  background: #fff;
  width: 32px;
  height: 32px;
  line-height: 34px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  margin: 15px 6px 12px 4px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.social-icon a:hover {
  color: #fff !important;
}

.social-icon .envelope:hover {
  background: #0378D4;
}

.social-icon .twitter:hover {
  background: #4a9cec;
}

.social-icon .instagram:hover {
  background: #d6274d;
}

.social-icon .linkedin:hover {
  background: #1260a2;
}

.social-icon .google:hover {
  background: #ce332a;
}
.form-input {
  margin-bottom: 1.25rem;
  width: 70%;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-duration: 300ms;
}
.form-input:focus {
  --tw-border-opacity: 1;
  border-color: rgba(241, 194, 50, var(--tw-border-opacity));
}

.rounded-full {
  border-radius: 9999px;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 194 50 / var(--tw-bg-opacity));
}
textarea {
  resize: vertical;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}
.btn,
[role=".btn"] {
  cursor: pointer;
  width: 150px;
  height: 48px;
  align-items: center;
  text-align: center;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 194, 50, var(--tw-bg-opacity));
}
