.container {
    /* width: 100%; */
    
  }
  table {
    /* width: 80%;
    margin: 0 auto; */
    border: 1px solid black;
  }
  td {
    /* width: 50%; */
    border: 1px solid black;
  }
  th{
    border: 1px solid black;
  }
  @media print {
    .nodownload {
        visibility: hidden;
    }
    @page {size: landscape}
  }