@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');

* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: 'Poppins', sans-serif;
}

section {
  position: relative;
  min-height: calc(100vh - 65px);
  /* background-color: whitesmoke; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
 
}

section .container {
  position: relative;
  width: 800px;
  height: 500px;
  background: #fff;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

section .container .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

section .container .user .imgBx {
  position: relative;
  width: 50%;
  height: 100%;
}

section .container .user .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section .container .user .formBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transition: 0.5s;
}

section .container .user .formBx form h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
}

section .container .user .formBx form input {
  position: relative;
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}

 section .container .user .formBx form button {
  /* position: relative;
  max-width: 1700px; */
  background: #F1C232;
  color: #fff;
  /*cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
  margin-bottom: 10px;
  margin: 8px 0;
  padding: 10px; */
} 
section .container .user .formBx form .googlebutton{
  background: #fff;
  color: rgb(65, 64, 64);
}
/* section .container .user .formBx form button {
  max-width: 100px;
  background: #677eff;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
} */

section .container .user .formBx form .signinup {
  position: relative;
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  text-transform: uppercase;
  font-weight: 300;
 
}

section .container .user .formBx form .signinup a {
  font-weight: 600;
  text-decoration: none;
  color: #F1C232;

}

section .container .user .formBx form .forget a {
  font-weight: 600;
  text-decoration: none;
  color: grey;
}



section .container .signinupBx {
  pointer-events: initial;
}


section .containe .signinupBx .formBx {
  left: 0;
}


section .container .signinupBx .imgBx {
  left: 0%;
}



@media (max-width: 991px) {
  section .container {
    max-width: 400px;
  }

  section .container .imgBx {
    display: none;
  }

  section .container .user .formBx {
    width: 100%;
  }
}

.errormsg{
  margin-bottom: 0;
  color: red;
  font-size: x-small;
}
.divider {
  border-top: 1px solid #d9dadc;
  display: block;
  line-height: 1px;
  margin: 15px 0;
  position: relative;
  text-align: center;
}
.or{
  background: #fff;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 0 20px;
    text-transform: uppercase;
}

.sign{
  margin-top: 140px;
}
